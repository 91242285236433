import React, { Component } from "react"
import { FiCheck } from "react-icons/fi"
import dictionary from "./dictionary"

class List extends Component {
  render() {
    const {
      column,
      stylevariation,
      listId,
      listContent,
      item,
      name,
    } = this.props
    let SectorContent = []

    if (listContent) {
      SectorContent = listContent
    }

    SectorContent = (dictionary || {})[listId || "default"] || []

    const list = SectorContent.slice(0, item)
    return (
      <React.Fragment>
        {list.map((value, index) => {
          const even = index % 2 === 0
          return (
            <div
              className={`rn-about-area ptb--40 ${
                index === 0 ? "pt--120" : ""
              } bg_color--5`}
            >
              <div className="container">
                <div
                  className={`row row--35 ${!even ? "flex-row-reverse" : ""}`}
                >
                  <div className="col-lg-6">
                    <div className="thumbnail">
                      <img
                        className="w-100"
                        src={value.image}
                        alt={value.title}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="about-inner inner">
                      <div className="section-title">
                        <h2 className="title">{value.title}</h2>
                        {value.description && Array.isArray(value.description)
                          ? value.description.map((desc, k) => {
                              switch (desc.type) {
                                case "p":
                                  return <p>{desc.content}</p>
                                case "ul":
                                  return (
                                    <>
                                      {((desc || {}).content || {}).header ? (
                                        <h4>{desc.content.header}</h4>
                                      ) : null}
                                      {((desc || {}).content || {}).items &&
                                      Array.isArray(
                                        ((desc || {}).content || {}).items
                                      ) ? (
                                        <ul className="list-style--1">
                                          {desc.content.items.map((i, k) => (
                                            <li key={k}>
                                              <FiCheck /> {i}
                                            </li>
                                          ))}
                                        </ul>
                                      ) : null}
                                    </>
                                  )
                                default:
                                  return desc.content
                              }
                            })
                          : null}
                      </div>
                      <div className="about-button mt--50">
                        <a
                          className="rn-button-style--2 btn-solid"
                          href={value.link}
                        >
                          {`TEKMENS ${value.category}`}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }
}
export default List
