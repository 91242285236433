import React from "react"

import { Sectors } from "../../config/assets"

export default {
  default: [
    {
      image: Sectors.Construction,
      category: "Construction",
      title: "CONSTRUCTION",
      description: [
        {
          type: "p",
          content:
            "Our Construction Sector is equipped to supply industry standard Construction Machineries and Concrete Equipments, and armed with a professional support team to provide after sale Service and Maintenance",
        },
        {
          type: "p",
          content:
            "Our Products range from leading manufacturers from Italy, Germany and China, and also world renowned top brands",
        },
        {
          type: "p",
          content:
            "We also provide reliable and authentic Genuine Spare Parts ",
        },
        {
          type: "ul",
          content: {
            header: "Our Solutions",
            items: [
              "Air & Power Solutions",
              "Concrete & Surfacing Solutions",
              "Material Handling Equipments",
              "PMV Solutions",
              "Engine & Drive Units",
              "Cutting Blades & Coring Bits",
            ],
          },
        },
        {
          type: "ul",
          content: {
            header: "Our Market Sectors",
            items: [
              "Construction Industry",
              "Oil and Gas",
              "Agriculture",
              "Landscaping",
            ],
          },
        },
      ],
      link: "/sectors/construction",
    },
    {
      image: Sectors.Environment,
      category: "Environment",
      title: "ENVIRONMENT",
      description: [
        {
          type: "p",
          content:
            "Our Environment Sector is adept at delevering end-to-end functional Solutions and Equipments in our specialize domain of Environmental, Hydrogeology Consultancy and Engineering",
        },
        {
          type: "p",
          content:
            "At our hoard of tailoring solutions to customer requirements, we supply a wide range of high tech Monitoring Equipments and Solutions covering the areas of our profeciency - Environment, Hydrometry, Health & Safety, Energy and Telemetry",
        },
        {
          type: "ul",
          content: {
            header: "Our Solutions",
            items: [
              "Environment Monitoring",
              "Hydrometry",
              "Health and Safety",
              "Telemetry & Telecommunication",
            ],
          },
        },
        {
          type: "ul",
          content: {
            header: "Our Market Sectors",
            items: ["Governmetn & Public", "Oil & Gas", "Energy & Water"],
          },
        },
      ],
      link: "/secotrs/environment",
    },
    {
      image: Sectors.MEP,
      category: "MEP",
      title: "MEP",
      description: [
        {
          type: "p",
          content:
            "Our MEP Sector is being focus primary on trade - catering to projects of desciplines that are HVAC, Electrical, Plumbing, Fire Fighting, Fire Alarm and ELV Systems",
        },
        {
          type: "p",
          content:
            "We Play as one-stop-shop for our Clients on any MEP Projects that we deal with, this helps saving remarkable investment of time and effort involved in those Projects",
        },
        {
          type: "html",
          content: (
            <p>
              As per <em>Going Green Social Responsibility</em>, we do practice
              energy saving methods and durable solutions that offer optimum
              running and minimum overall cost in the long run
            </p>
          ),
        },
        {
          type: "ul",
          content: {
            header: "Our Solutions",
            items: [
              "Plumbing Products",
              "Electrical Products",
              "HVAC Products",
              "Fire Fighting Products",
              "Fire Alarm Systems",
              "ELV Systems",
            ],
          },
        },
        {
          type: "ul",
          content: {
            header: "Our Market Sectors",
            items: [
              "Water & Electricity",
              "Commercial, Inudstrial and Residential",
              "Retail",
              "Government",
              "Healthcare & Hospitality",
            ],
          },
        },
      ],
      link: "/sectors/mep",
    },
  ],
}
