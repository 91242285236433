import React, { Component } from "react"
import { Construction, Climate, Engineering } from "../icons"

const Sectors = [
  {
    icon: <Construction width={84} height={84} />,
    title: "Construction",
    description:
      "Supply of Concrete Equipments, Material Handling Equipments, Indistruail Air Compressors and Power Generators",
  },
  {
    icon: <Climate width={84} height={84} />,
    title: "Environment",
    description:
      "Provides Weather, Air, Water Management Monitoring Tools and Telemetry Software Solutions",
  },
  {
    icon: <Engineering width={84} height={84} />,
    title: "MEP",
    description:
      "Supply of Plumbing, Electrical, Fire Protection Products and HVAC Products & Controls",
  },
]

class SectorHeader extends Component {
  render() {
    return (
      <div className="slider-activation">
        <div
          className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--sectors-bg"
          data-black-overlay="6"
        >
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h1 className="title theme-gradient">
                    An Engineering <br /> Company.{" "}
                  </h1>
                </div>
              </div>
            </div>
            <div className="service-wrapper service-white">
              <div className="row">
                {Sectors.map((val, i) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <div className="service service__style--1">
                      <div className="icon">{val.icon}</div>
                      <div className="content">
                        <h4 className="title">{val.title}</h4>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default SectorHeader
