import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import SectorsHeader from "../../components/elements/SectorsHeader"
import SectionList from "../../components/Section/List"

const IndexPage = () => (
  <Layout>
    <SEO title="Our Sectors" />

    {/* Start Sector Header Area */}
    <SectorsHeader />
    {/* End Sector Header Area */}

    {/* Start SectorsSummary Area  */}
    <SectionList item={3} />
    {/* End SectorsSummary Area  */}
  </Layout>
)

export default IndexPage
